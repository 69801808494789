
import {Component, Mixins, Ref, Vue} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {MDB} from "@/plugins/uyap-plugin/MDB";
import {VForm} from "@/types";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import XmlController from "@/plugins/uyap-plugin/takip_ac/XmlController";
import {Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import TakipHazirlaEvrakEkleForm from "@/components/eicrapro/TakipHazirlaEvrakEkleForm.vue";
import {FileEntity} from "@/entity/FileEntity";
import TarafInterface from "@/plugins/uyap-plugin/takip_ac/parser/TarafInterface";
import DayanakList from "@/components/eicrapro/DayanakList.vue";
import FormButton from "@/components/dialogs/FormButton.vue";
import {OdemeTipiEnum} from "@/plugins/uyap-plugin/takip_ac/OdemeTipleri";
import {DosyaFormDataInterface} from "@/plugins/uyap-plugin/takip_ac/DosyaFormDataInterface";
import {ProgramPaths} from "@/enum/ProgramPaths";
import MahiyetSecForm from "@/components/eicrapro/MahiyetSecForm.vue";
import {IcraTakipMahiyetleriCevapRow} from "@/plugins/uyap-plugin/uyap/IcraTakipMahiyetleri";
import TakipXmlParser from "@/plugins/uyap-plugin/takip_ac/TakipXmlParser";
import TakipHazirlaDayanakEkleForm from "@/components/eicrapro/TakipHazirlaDayanakEkleForm.vue";
import {AdliyelerCevap} from "@/plugins/uyap-plugin/uyap/Adliyeler";
import UyapIlamBilgileriForm from "@/components/eicrapro/UyapIlamBilgileriForm.vue";
import TakipAcVekaletEkleForm from "@/components/eicrapro/TakipAcVekaletEkleForm.vue";
import NewFormDialog from "@/components/dialogs/NewFormDialog.vue";
import {IbanTercihiEnum} from "@/plugins/uyap-plugin/uyap/IbanTercihiEnum";
import {IIban} from "@/plugins/uyap-plugin/uyap/AvukatKayitliIbanList";
import UyapTakipAc from "@/plugins/uyap-plugin/takip_ac/UyapTakipAc";
import {TakipAcStatusEnum} from "@/plugins/uyap-plugin/takip_ac/parser/TakipAcStatusEnum";

@Component({
  computed: {
    IbanTercihiEnum() {
      return IbanTercihiEnum
    },
    OdemeTipiEnum() {
      return OdemeTipiEnum
    }
  },
  components: {
    NewFormDialog,
    TakipAcVekaletEkleForm,
    UyapIlamBilgileriForm,
    TakipHazirlaDayanakEkleForm,
    MahiyetSecForm, FormButton, DayanakList, TakipHazirlaEvrakEkleForm, FormDialog, Tutar
  }
})

export default class TakipHazirlaFormWizard extends Mixins(ObjectInputMixin) {
  @Ref('form') readonly form!: VForm;

  secilenDosyalar: Array<UyapTakipAc> = [];
  localValue: DosyaFormDataInterface = new DosyaFormDataInterface();
  activeStep: number = 1;
  onFly = false;
  parentTaskId = null;
  mdb = new MDB();
  takipHazirlaStatus = TakipHazirlaStatus;
  stepperHeaders = ['Takip Bilgileri', 'Takipler Hazırlanıyor', 'İşlemler Sıraya Alındı'];
  dosyalar: Array<UyapTakipAc> = [];
  tevziTipiDaireDisabled: boolean = true;
  tevziTipiGayrimenkulDisabled: boolean = true;
  durumObj = {
    1 : {
      icon: "mdi-clock-time-nine",
      label: "Sırada",
      color: "grey"
    },
    2 : {
      icon: "load",
      label: "İşleniyor",
      color: ""
    },
    3 : {
      icon: "mdi-alert",
      label: "Vekalet Yok",
      color: "orange"
    },
    4 : {
      icon: "mdi-progress-check",
      label: "Tamamlandı (İşlemlerde Sıraya Almaya Hazır)",
      color: "green"

    },
    5 : {
      icon: "mdi-check",
      label: "Tamamlandı",
      color: "green"
    }
  }
  headers = [
    {text: "#", value: "tempId", width: '20'},
    {text: "Alacaklı", value: "alacaklilar"},
    {text: "Borçlu", value: "borclular"},
    {text: "Borçlu Vekili", value: "vekil"},
    {text: "IBAN", value: "iban"},
    {text: "Takibe Esas Tutar", value: "toplamTakibeEsasMiktar", width: "175"},
    {text: "İşlemler", value: "actions", align: "center", width: "110"},
    {text: "Durum", value: "status", width: "80", align: "center"}
  ]
  isParsing = false;
  adliyeler: AdliyelerCevap = [];
  kotaKullanimSekliItems = [{text: 'Avukat', value: '0'}, {text: 'Kurum', value: '1'}]
  ibanTercihiItems = [
    {text: 'Avukat', value: 'Avukat'},
    {text: 'Müvekkil', value: 'Müvekkil'},
    {text: "IBAN'sız", value: 'Yok'}
  ];
  durumAciklamasi = '';
  vekaletCache: Array<{ hash: string, data: FileEntity }> = [];
  mahiyetler: Array<{ mahiyetTuru: string, items: Array<IcraTakipMahiyetleriCevapRow> }> = [];
  mahiyetCheck = true;
  ilamCheck = true;
  vekaletCheck: Array<any> = [];
  avukatIbanlari: Array<IIban> = [];
  sorgulanmaDurumu = 0;
  isRunQuery= false;
  isDragOver = false;

  detayListHeaders = [
    {icon: "mdi-account", title: "TCKN", key: "temelBilgileri.tcKimlikNo", show: "KISI"},
    {icon: "mdi-account", title: "Vergi No", key: "temelBilgileri.vergiNo", show: "KURUM"},
    {icon: "mdi-database-marker-outline", title: "Mernis/Mersis Adresi Kullan", key: "mernisAdresiKullan"},
    {icon: "mdi-map-marker-question-outline", title: "Mernis/Mersis Adresi Var Mı", key: "mernisAdresiVarMi"},
    {icon: "mdi-map", title: "İl/İlçe", key: ["adresBilgisi.ilAdi", "adresBilgisi.ilceAdi"]},
    {icon: "mdi-map-marker-outline", title: "Adres", key: "adresBilgisi.adres"},
  ]

  alacakKeys = [
    {key: "ilamliList", title: "İlamlı", aciklamaKey: ["dosyaNo","ilamliKurumAdi"], tarihKey: "ilamTarihi"},
    {key: "cekList", title: "Çek", aciklamaKey: "seriNumarasi", tarihKey: "kesideTarihi"},
    {key: "senetList", title: "Senet", aciklamaKey: "ilamsizTipi", tarihKey: "tanzimTarihi"},
    {key: "policeList", title: "Poliçe", aciklamaKey: "ilamsizTipi", tarihKey: "tanzimTarihi"},
    {key: "kontratList", title: "Kontrat", aciklamaKey: "tasinmazAdresiDetay", tarihKey: "hazirlanisTarihi"},
    {key: "digerList", title: "Diğer", aciklamaKey: "aciklama", tarihKey: "alacakTarihi"},
  ]

  alacakHeaders = [
    {text: "Tarih", value: "tarih"},
    {text: "Açıklama", value: "aciklama"},
    {text: "Tutar", value: "meblagi"},
    {text: "Para Birimi", value: "meblagTuruAciklama"},
  ]

  alacakKalemiHeaders = [
    {text: "Açıklama", value: "temelBilgileri.aciklama"},
    {text: "Tutar", value: "temelBilgileri.alacakTutari"},
    {text: "Para Birimi", value: "temelBilgileri.selectedParaBirimiAciklama"},
  ]

  mounted() {
    window.addEventListener('dragover', this.handleDragEnter);
    this.$uyap.AvukatKayitliIbanList().run({}).then((ibanlar) => {
      this.avukatIbanlari = ibanlar.value;
      this.localValue.avukatIban = this.avukatIbanlari[0].ibanNumarasi;
    })
  }

  beforeDestroy() {
    window.removeEventListener('dragover', this.handleDragEnter);
  }

  handleDragEnter(event:any) {
    event.preventDefault();
    this.isDragOver = true; // Alanın üzerine sürükleniyor
  }

  handleFileDrop(event: any) {
    this.isDragOver = false
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length) {
      const file = droppedFiles[0];
      if (file.type === 'application/xml' ||
          file.type === 'text/xml' ||
          file.name.split('.').pop().toLowerCase() == 'xml') {
        this.localValue.xml = file;
        this.input();
      } else {
        // Geçersiz dosya türü için hata yönetimi (isteğe bağlı)
        this.$toast.warning('Lütfen bir XML dosyası yükleyin.');
      }
    }
  }

  async onIlChange() {
    if (this.localValue.il) {
      this.adliyeler = await this.$uyap.Adliyeler().run({ilKodu: this.localValue.il});
    } else
      this.adliyeler = [];
    this.input();
  }

  copy(key: string | Array<string>, taraf: any) {
    let text = (Array.isArray(key) ? key.map(k => this.$helper.getNestedValue(taraf, k)).join(' ') : this.$helper.getNestedValue(taraf, key));
    if (typeof text == "boolean")
      text = text ? "Evet" : "Hayır";
    this.$helper.clickCopyText(text);
  }

  getData(key: string | Array<string>, taraf: any): string {
    if (Array.isArray(key)) {
      return key.map(k => this.$helper.getNestedValue(taraf, k)).join(' ');
    } else {
      let text = this.$helper.getNestedValue(taraf, key);
      if (typeof text == "boolean")
        text = text ? "Evet" : "Hayır";
      return text;
    }
  }

  changeAdres(taraf: TarafInterface, adres: any, index: number) {
    taraf.adresBilgisi = adres;
    taraf.adresListPickedIndex = index;
    if (taraf.temelBilgileri.kisiKurumId) {
      this.dosyalar.forEach(dosya => dosya.parsedFile.tarafList.forEach(tarafItem => {
        if (tarafItem.temelBilgileri.kisiKurumId == taraf.temelBilgileri.kisiKurumId) {
          tarafItem.adresBilgisi = adres;
          tarafItem.adresListPickedIndex = index;
        }
      }));
    }
  }

  changeIban(taraf: any, iban: any, index: number) {
    taraf.hesapBilgisi = iban.hesapBilgisi;
    taraf.isVekilIban = (iban.kisi == "Avukat");
    taraf.selectedIbanIndex = index;
    if (taraf.temelBilgileri.kisiKurumId) {
      this.dosyalar.forEach(dosya => dosya.parsedFile.tarafList.forEach(tarafItem => {
        if (tarafItem.temelBilgileri.kisiKurumId == taraf.temelBilgileri.kisiKurumId) {
          taraf.hesapBilgisi = iban.hesapBilgisi;
          taraf.isVekilIban = (iban.kisi == "Avukat");
          taraf.selectedIbanIndex = index;
        }
      }));
    }
  }

  onAdliyeChange() {
    //@ts-ignore
    let adliye = this.adliyeler.find(x => x.adliyeBirimID == this.localValue.adliye);
    //@ts-ignore
    if (adliye.gayriMenkulIcraDairesiSayisi > 0) {
      this.tevziTipiGayrimenkulDisabled = false;
    } else {
      this.tevziTipiGayrimenkulDisabled = true;
    }
    //@ts-ignore
    if (adliye.bankaIcraDairesiSayisi > 0) {
      this.tevziTipiDaireDisabled = false;
    } else {
      this.tevziTipiDaireDisabled = true;
    }
    this.input();
  }

  fileRule(file: any) {
    if (file) {
      let uzanti = file.name.split('.').pop().toLowerCase();
      if (uzanti == "xml")
        return true;
      else
        return "Sadece xlm dosya yüklenebilir.";
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  tumDosyalariSec() {
    if (this.secilenDosyalar.length == this.dosyalar.length) {
      this.secilenDosyalar = [];
    } else {
      this.secilenDosyalar = this.dosyalar;
    }
  }

  async nextStep() {
    if (this.activeStep == 1 && this.form.validate()) {
      try {
        this.activeStep++;
        this.isParsing = true;
        // @ts-ignore
        let xml = await XmlController.readXmlWithEncoded(this.localValue.xml);
        let parsedXml = await XmlController.parse(xml, this.localValue);
        let dosya: TakipXmlParser;
        let sorgulanmisDosyalar = [];
        let sorgulananDosyaSayisi = 0;
        this.sorgulanmaDurumu=0;
        for (dosya of parsedXml) {

          this.isRunQuery = true;
          const index: number = parsedXml.indexOf(dosya);
          let takipAc = new UyapTakipAc();
          takipAc.parsedFile = dosya;
          try {
            await takipAc.runQuery();
          }catch (er) {
            takipAc.status = TakipAcStatusEnum.QUERY_FAIL;
            takipAc.addMessage("error",er.message);
          }finally {
            sorgulananDosyaSayisi++;
            this.sorgulanmaDurumu = (100/parsedXml.length) * sorgulananDosyaSayisi;
            // @ts-ignore
            takipAc.status = TaskDurum.SIRADA;
            // @ts-ignore
            takipAc.tempId = index + 1;
            sorgulanmisDosyalar.push(takipAc);
          }

        }
        this.dosyalar = sorgulanmisDosyalar;
        this.secilenDosyalar = this.dosyalar;
        this.dosyalar.forEach((dosya) => {
          if (dosya.parsedFile.icraDosyaBilgileri.isMahiyet()) {
            let findMahiyet = this.mahiyetler.find(mahiyet => mahiyet.mahiyetTuru == dosya.parsedFile.icraDosyaBilgileri.mahiyetTuru);
            if (!findMahiyet)
              this.mahiyetler.push({
                mahiyetTuru: dosya.parsedFile.icraDosyaBilgileri.mahiyetTuru,
                items: dosya.parsedFile.icraDosyaBilgileri.takipMahiyetleri
              })
          }
        });
        console.log('dosyalar', this.dosyalar)
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isParsing = false;
        this.isRunQuery = false;
        this.sorgulanmaDurumu = 0;
      }
    }
    this.focusStep(this.activeStep);
  }

  async takipHazirla(hizliAc: boolean) {
    this.onFly = true;
    let dosya: any;
    for (dosya of this.secilenDosyalar) {
      if (dosya.status != this.takipHazirlaStatus.tamamlandi_hazir &&
          dosya.status != this.takipHazirlaStatus.tamamlandi_bitti) {
        dosya.status = this.takipHazirlaStatus.isleniyor;
        if (dosya.parsedFile.vekalet && dosya.parsedFile.vekalet.data) {
          this.durumAciklamasi = '#' + dosya.tempId + ' dosyanın vekaleti kontrol ediliyor';
          let vekaletData = await this.vekaletKontrol(dosya);
          dosya.parsedFile.vekaletObject = vekaletData;
          delete dosya.parsedFile.vekalet;
          this.durumAciklamasi = '#' + dosya.tempId + ' dosya hazır';
        } else {
          this.durumAciklamasi = '#' + dosya.tempId + ' dosyanın vekaleti yok';
        }
        dosya.status = this.takipHazirlaStatus.tamamlandi_hazir;
      }
    }
    if (hizliAc)
      await this.createTaskHizliTakip();
    else
      await this.createTask();
    this.onFly = false;
  }

  async vekaletKontrol(dosya: any): Promise<FileEntity> {
    let vekaletData: FileEntity;
    let vekalet = dosya.parsedFile.vekalet.data;
    const Crypto = require('crypto');
    let hash = Crypto.createHash('sha1').update(vekalet).digest().toString('hex');
    let formData = new FormData();
    let vekaletBlob = this.$helper.base64toBlob(vekalet, dosya.parsedFile.vekalet.mimeType);
    let findCache = this.vekaletCache.find(cache => cache.hash == hash);
    if (!findCache) {
      formData.append('vekilList', JSON.stringify(dosya.parsedFile.vekilList));
      formData.append('tarafList', JSON.stringify(dosya.parsedFile.tarafList.filter((taraf: TarafInterface) => taraf.tarafSifati.rolID == 21)));
      formData.append('aciklama', dosya.parsedFile.vekalet.name);
      formData.append('name', dosya.parsedFile.vekalet.name);
      formData.append('file', vekaletBlob);
      formData.append('dokuman_turu_id', '1');
      formData.append('hash', hash);
      formData.append('mime_type', dosya.parsedFile.vekalet.mimeType);
      vekaletData = await this.$http.post('/api/v1/task/vekalet-senkron', formData);
      this.vekaletCache.push({hash: hash, data: vekaletData});
    } else {
      vekaletData = findCache.data;
    }
    return vekaletData;
  }

  // dosya kaydetme hatası düzeltilene kadar kaldırma
  waitThisLong(saniye: number) {
    return new Promise(resolve => setTimeout(resolve, saniye * 1000))
  }

  async createTask() {
    if (this.secilenDosyalar.length == 0) {
      this.$toast.warning('Hazırlanacak takipleri seçiniz.');
      return;
    }
    this.mahiyetCheck = true;
    this.secilenDosyalar.forEach(dosya => {
      if (dosya.parsedFile.icraDosyaBilgileri.isMahiyet() && !dosya.parsedFile.icraDosyaBilgileri.mahiyetId)
        this.mahiyetCheck = false;
      if (dosya.parsedFile.isIlamli() && !dosya.parsedFile.ilamSorgusu.status)
        this.ilamCheck = false;
    });
    if (!this.mahiyetCheck || !this.mahiyetCheck)
      return;
    let tasks: Array<Task> = [];
    let hazirDosyalar = this.secilenDosyalar.filter((dosya: any) => dosya.status == this.takipHazirlaStatus.tamamlandi_hazir);
    for (let dosya of hazirDosyalar) {
      let dayanaklar = [];
      for (const dayanak of dosya.parsedFile.dayanaklar) {
        let formData = new FormData();
        let base64 = dayanak.data;
        const Crypto = require('crypto');
        let hash = Crypto.createHash('sha1').update(base64).digest().toString('hex');
        let blob = this.$helper.base64toBlob(base64, dayanak.mimeType);
        formData.append('aciklama', dayanak.name);
        formData.append('name', dayanak.name);
        formData.append('file', blob);
        formData.append('hash', hash);
        formData.append('dokuman_turu_id', "9");
        formData.append('mime_type', dayanak.mimeType);
        let dayanakResponse = await this.$http.post('/api/v1/file', formData);
        dayanaklar.push(dayanakResponse.data);
        // dosya kaydetme hatası düzeltilene kadar kaldırma
        await this.waitThisLong(1);
      }
      dosya.parsedFile.dayanaklar = dayanaklar;
      // @ts-ignore
      dosya.xmlName = this.localValue.xml.name;
      let task: Task = new Task();
      task.parent_id = this.parentTaskId;
      task.task_type_id = TaskTuru.takip_hazirla;
      task.data = dosya
      tasks.push(task);
    }
    let response = await this.$store.dispatch('postTasks', tasks);
    if (response)
      this.parentTaskId = response.data.id;
    this.islemlereGit();
    this.activeStep++;
  }

  async createTaskHizliTakip(){
    if (this.secilenDosyalar.length == 0) {
      this.$toast.warning('Hazırlanacak takipleri seçiniz.');
      return;
    }
    this.mahiyetCheck = true;
    this.secilenDosyalar.forEach(dosya => {
      if (dosya.parsedFile.icraDosyaBilgileri.isMahiyet() && !dosya.parsedFile.icraDosyaBilgileri.mahiyetId)
        this.mahiyetCheck = false;
    });
    if (!this.mahiyetCheck)
      return;
    this.vekaletCheck = [];
    this.secilenDosyalar.forEach(dosya => {
      if (!dosya.parsedFile.vekaletObject?.id) {
        // @ts-ignore
        this.vekaletCheck.push(dosya.tempId);
        // @ts-ignore
        dosya.status = this.takipHazirlaStatus.vekalet_yok;
      }
    });
    if (this.vekaletCheck.length>0)
      return;
    let tasks: Array<Task> = [];
    let hazirDosyalar = this.secilenDosyalar.filter((dosya: any) => dosya.status == this.takipHazirlaStatus.tamamlandi_hazir);
    for (let dosya of hazirDosyalar) {
      let dayanaklarObject: Array<FileEntity> = [];
      for (const dayanak of dosya.parsedFile.dayanaklar) {
        let formData = new FormData();
        let base64 = dayanak.data;
        const Crypto = require('crypto');
        let hash = Crypto.createHash('sha1').update(base64).digest().toString('hex');
        let blob = this.$helper.base64toBlob(base64, dayanak.mimeType);
        formData.append('aciklama', dayanak.name);
        formData.append('name', dayanak.name);
        formData.append('file', blob);
        formData.append('hash', hash);
        formData.append('dokuman_turu_id', "9");
        formData.append('mime_type', dayanak.mimeType);
        let dayanakResponse = await this.$http.post('/api/v1/file', formData);
        dayanaklarObject.push(dayanakResponse.data);
        // dosya kaydetme hatası düzeltilene kadar kaldırma
        await this.waitThisLong(1);
      }
      dosya.parsedFile.dayanaklarObject = dayanaklarObject;
      // @ts-ignore
      dosya.xmlName = this.localValue.xml.name;
      let task: Task = new Task();
      task.parent_id = this.parentTaskId;
      task.task_type_id = TaskTuru.hizli_takip_ac;
      task.data = dosya
      tasks.push(task);
    }
    let response = await this.$store.dispatch('postTasks', tasks);
    if (response)
      this.parentTaskId = response.data.id;
    this.islemlereGit();
    this.activeStep++;
  }

  addAllVekalet(vekalet: any) {
    this.dosyalar.forEach((dosya: any) => {
      if (!dosya.parsedFile.vekalet)
        dosya.parsedFile.vekalet = vekalet;
    });
  }

  addAllDayanak(dayanaklar: Array<any>) {
    this.dosyalar.forEach((dosya: any) => {
      dayanaklar.forEach(dayanak => {
        dosya.parsedFile.dayanaklar.push(dayanak);
      });
    });
  }

  selectAllMahiyet(mahiyet: Array<{ mahiyetTuru: string, value: number }>) {
    this.dosyalar.forEach(dosya => {
      if (dosya.parsedFile.icraDosyaBilgileri.isMahiyet()) {
        dosya.parsedFile.icraDosyaBilgileri.mahiyetId = mahiyet.find(m => m.mahiyetTuru == dosya.parsedFile.icraDosyaBilgileri.mahiyetTuru)?.value ?? null;
      }
    })
  }

  previousStep() {
    if (this.activeStep > 1) {
      this.activeStep--;
    }
    this.focusStep(this.activeStep);
  }

  focusStep(step: any) {
    let activeHeader = (this.$refs['step' + step] as Vue[])[0].$el;
    let headers = this.$refs['headers'] as Element;
    let activeHeaderRight = activeHeader.getBoundingClientRect().right;
    let headersLeft = headers.getBoundingClientRect().left;
    let headersWidth = headers.getBoundingClientRect().width;
    let scrollPosition = (activeHeaderRight - headersLeft) - headersWidth;
    if (scrollPosition < 0) {
      scrollPosition = 0;
    }
    headers.scrollLeft += scrollPosition;
  }

  islemlereGit() {
    this.$router.push(ProgramPaths.eicrapro + '/islemler');
  }

  sifirla() {
    this.localValue = new DosyaFormDataInterface();
    this.input();
    this.activeStep = 1;
  }
}

enum TakipHazirlaStatus {
  sirada = 1,
  isleniyor,
  vekalet_yok,
  tamamlandi_hazir,
  tamamlandi_bitti
}
